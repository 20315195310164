<template>
    <div>
        <v-dialog v-model="appointmentDialog" transition="slide-x-transition">
            <v-form ref="formAppointment" lazy-validation>
                <v-card flat class="right-side-dialog-750">
                    <v-toolbar flat>
                        <v-card-title class="headline">
                            <span class="primary--text-kh">បំពេញព័ត៌មានចៅក្រមជំនុំជម្រះ</span>
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn text fab dark large color="primary" @click="appointmentDialog=false">
                            <v-icon>{{icons.mdiClose}}</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <center>
                            <v-alert text type="info">
                                <h3 class="text-h6">
                                    <span class="primary--text"> ឈ្មោះក្រឡាបញ្ជី៖ {{clerkName}}</span>
                                </h3>
                            </v-alert>

                        </center>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="appointment.letterNo" label="លេខលិខិត" outlined dense hide-details :rules="rule.requiredField()">
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-text-field v-model="appointment.appointmentDate" label="កាលបរិច្ឆេទ" outlined dense type="date" hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-select v-model="appointment.staff" outlined label="ឈ្មោះចៅក្រមជម្រះ" :items="judgeList" return-object :rules="rule.requiredField()" hide-details dense>
                                    <template slot="selection" slot-scope="data">
                                        <span v-if="$t('lang') == 'KH'">
                                            {{data.item.nameKh}}
                                        </span>
                                        <span v-else>{{data.item.nameEn}}</span>
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        <span v-if="$t('lang') == 'KH'">
                                            {{data.item.nameKh}}
                                        </span>
                                        <span v-else>{{data.item.nameEn}}</span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select v-model="appointment.judRoleId" outlined label="តួនាទី" item-value="id" item-text="roleNameKh" :items="judgeRoles" :rules="rule.requiredField()" hide-details dense>
                                    <template slot="selection" slot-scope="data">
                                        <span v-if="$t('lang') == 'KH'">
                                            {{data.item.roleNameKh}}
                                        </span>
                                        <span v-else>{{data.item.roleNameEn}}</span>
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        <span v-if="$t('lang') == 'KH'">
                                            {{data.item.roleNameKh}}
                                        </span>
                                        <span v-else>{{data.item.roleNameEn}}</span>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row v-for="(item, index) in additionalJudges" :key="item.id">
                            <v-col cols="12" sm="6">
                                <v-select v-model="item.staff" outlined label="ឈ្មោះចៅក្រមជម្រះ" :items="judgeList" return-object :rules="rule.requiredField()" hide-details dense>
                                    <template slot="selection" slot-scope="data">
                                        <span v-if="$t('lang') == 'KH'">
                                            {{data.item.nameKh}}
                                        </span>
                                        <span v-else>{{data.item.nameEn}}</span>
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        <span v-if="$t('lang') == 'KH'">
                                            {{data.item.nameKh}}
                                        </span>
                                        <span v-else>{{data.item.nameEn}}</span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="5">
                                <!--
                                <v-text-field label="តួនាទី" outlined v-model="appointment.role" hide-details dense>
                                </v-text-field>
                                -->
                                <v-select v-model="item.judRoleId" outlined label="តួនាទី" item-value="id" item-text="roleNameKh" :items="judgeRoles" :rules="rule.requiredField()" hide-details dense>
                                    <template slot="selection" slot-scope="data">
                                        <span v-if="$t('lang') == 'KH'">
                                            {{data.item.roleNameKh}}
                                        </span>
                                        <span v-else>{{data.item.roleNameEn}}</span>
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        <span v-if="$t('lang') == 'KH'">
                                            {{data.item.roleNameKh}}
                                        </span>
                                        <span v-else>{{data.item.roleNameEn}}</span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="1">
                                <v-icon large color="primary" @click="removeJudge(index)">{{icons.mdiDelete}}</v-icon>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5 justify-center">
                            <v-col cols="4" align="center">
                                <v-btn class="btn primary" @click="addAppointedTrial($event)" small rounded>
                                    <v-icon small>{{icons.mdiPlus}}</v-icon> ចៅក្រម
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="flex text-center justify-center">
                        <v-spacer></v-spacer>
                        <v-btn class="btn primary flex-grow-1" rounded @click="createAppointment">
                            {{$t('button.save')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <div v-if="appointmentList.length > 0" class="flex">
            <v-card-title>
                <span class="primary--text">ការចាត់តាំងចៅក្រមជម្រះ</span>
            </v-card-title>
            <v-card-text>
                <v-data-table dense class="borderless-table elevation-1" :headers="appointmentHeader" :items="appointmentList" :hide-default-footer="true">
                    <template v-slot:[`item.staff`]="{ item }">
                        <span v-if="item.staff != null">{{item.staff.nameKh}}</span>
                    </template>
                </v-data-table>
            </v-card-text>
        </div>
        <div class="flex text-center my-5">
            <v-btn class="primary--text" rounded outlined @click="appointmentDialog=true">
                <v-icon dark left>{{icons.mdiPlus}}</v-icon> ចាត់តាំងចៅក្រមជម្រះ
            </v-btn>
        </div>
        <div v-if="appointmentList.length > 0">
            <v-tabs v-model="prosTab" fixed-tabs dark slider-color="#fff">
                <v-tab :value="0">ការកោះហៅ</v-tab>
                <v-tab :value="1">ឯកសារមេធាវីនិងជនទាក់ទិន</v-tab>
                <v-tab :value="2">ជនទាក់ទិន</v-tab>
                <v-tab :value="3">ភស្តុតាង</v-tab>
                <v-tab :value="4">កិច្ចការបានអនុវត្ត</v-tab>
                <v-tab :value="5">ការសម្រេចរបស់ចៅក្រមជម្រះ</v-tab>
            </v-tabs>
            <v-window v-model="prosTab">
                <v-window-item key="0">
                    <v-card-text>
                        <br />
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-card-title class="printIcon">
                                        <span class="primary--text-kh">ប្រវត្តិការកោះហៅ</span>
                                    </v-card-title>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card-text>
                                        <v-data-table class="borderless-table elevation-1" :headers="warrantHistoryHeader" :items="warrantHistoryList">
                                            <template v-slot:[`item.no`]="{ item }">
                                                <v-chip color="primary" outlined @click="detailWarrent(item)">
                                                    {{warrantHistoryList.indexOf(item)+1}}
                                                </v-chip>
                                            </template>
                                            <template v-slot:[`item.inCharge`]="{ item }">
                                                <span v-if="item.phase == 2">
                                                    {{item.judgeName}}
                                                </span>
                                                <span v-else>{{item.prosecutorName}}</span>
                                            </template>
                                            <template v-slot:[`item.phase`]="{ item }">
                                                <span v-if="item.phase == 2">
                                                    ចៅក្រមស៊ើប
                                                </span>
                                                <span v-else>អយ្យការ</span>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                    <v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="openWarrantDialog">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :headers="warrantHeader" :items="warrantList">
                            <template v-slot:[`item.no`]="{ item }">
                                {{warrantList.indexOf(item)+1}}
                            </template>
                            <template v-slot:[`item.caller`]="{ item }">
                                <span v-if="item.caller">{{item.caller.nameKh}}</span>
                            </template>

                            <template v-slot:[`item.parties`]="{ item }">
                                <v-chip class="ma-1" v-for="party in item.parties" :key="party.id">
                                    {{party.nameKh}}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon medium color="primary" class="mr-2" @click="editWarrant(item)">{{icons.mdiReceiptTextEdit}}</v-icon>
                            </template>
                        </v-data-table>

                        <v-dialog v-model="warrantDialog" transition="slide-x-transition" @input="onWarrantDialogClose">
                            <v-card class="right-side-dialog-800">
                                <v-toolbar flat>
                                    <v-card-title>
                                        <span class="primary--text-kh">បញ្ចូលការកោះហៅ</span>
                                    </v-card-title>
                                    <h4 class="h-subtitle">ដំណាក់កាលចៅក្រមជម្រះ</h4>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="onWarrantDialogClose">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text class="mt-5">
                                    <v-form ref="formWarrant" lazy-validation>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="warrantData.warrantLetterNo" label="លេខដីកាកោះ" outlined dense hide-details>
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="warrantData.warrantDate" label="កាលបរិច្ឆេទ" outlined dense type="date" hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-select v-model="warrantData.caller" outlined label="ឈ្មោះចៅក្រមជម្រះ" :items="appointedJudgeList" return-object :rules="rule.requiredField()" hide-details dense>
                                                    <template slot="selection" slot-scope="data">
                                                        <span v-if="$t('lang') == 'KH'">
                                                            {{data.item.nameKh}}
                                                        </span>
                                                        <span v-else>{{data.item.nameEn}}</span>
                                                    </template>
                                                    <template slot="item" slot-scope="data">
                                                        <span v-if="$t('lang') == 'KH'">
                                                            {{data.item.nameKh}}
                                                        </span>
                                                        <span v-else>{{data.item.nameEn}}</span>
                                                    </template>

                                                </v-select>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="clerkName" label="ឈ្មោះក្រឡាបញ្ជី" readonly outlined dense hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-select v-model="warrantData.letterType" outlined label="ប្រភេទដីកា" :items="getCaseLetterTypeByTypeId(2)" return-object :rules="rule.requiredField()" hide-details dense>
                                                    <template slot="selection" slot-scope="data">
                                                        <span v-if="$t('lang') == 'KH'">
                                                            {{data.item.letterNameKh}}
                                                        </span>
                                                        <span v-else>{{data.item.letterNameEn}}</span>
                                                    </template>
                                                    <template slot="item" slot-scope="data">
                                                        <span v-if="$t('lang') == 'KH'">
                                                            {{data.item.letterNameKh}}
                                                        </span>
                                                        <span v-else>{{data.item.letterNameEn}}</span>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="warrantData.appearanceDate" label="កាលបរិច្ឆេទបង្ហាញខ្លួន" outlined dense type="date" hide-details></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select v-model="warrantData.parties" outlined label="ភ្ជាប់ឈ្មោះជនទាក់ទិន" :items="relatedPersonList" item-text="nameKh" return-object dense clearable chips multiple hide-details>
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip small>{{ item.nameKh }}</v-chip>
                                                    </template>
                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                            <v-list-item-action>
                                                                <v-checkbox :input-value="active"></v-checkbox>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-row no-gutters align="center">
                                                                        <span>{{ item.nameKh }}</span>
                                                                        <v-spacer></v-spacer>
                                                                        <v-chip small>{{ item.partyRole.roleNameKh }}</v-chip>
                                                                    </v-row>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="btn primary flex-grow-1" rounded @click="createWarrant">{{$t('button.save')}}</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-card-text>
                </v-window-item>
                <v-window-item key="1">
                    <v-card-text>
                        <br />
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-card-title class="printIcon">
                                        <span class="primary--text-kh">ប្រវត្តិឯកសារមេធាវីនិងជនទាក់ទិន</span>
                                    </v-card-title>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card-text>
                                        <v-data-table class="borderless-table elevation-1" :headers="documentHistoryHeader" :items="documentHistoryList">
                                            <template v-slot:[`item.no`]="{ item, index }">
                                                {{index+1}}
                                            </template>
                                            <template v-slot:[`item.caseLetterType`]="{ item }">
                                                <span v-if="item.caseLetterType != null">
                                                    {{item.caseLetterType.letterNameKh}}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.parties`]="{ item }">
                                                <v-chip class="ma-1" v-if="item.parties.length > 0" v-for="party in item.parties" :key="party.id">
                                                    {{party.nameKh}}
                                                </v-chip>
                                            </template>
                                            <template v-slot:[`item.lawyerInfo`]="{ item }">
                                                <v-btn class="btn" small rounded outlined @click="viewLawyer(item)">
                                                    {{$t('button.lawyerInfo')}}
                                                </v-btn>
                                            </template>
                                            <template v-slot:[`item.phase`]="{ item }">
                                                <span v-if="item.phase == 2">
                                                    ចៅក្រមស៊ើប
                                                </span>
                                                <span v-else>អយ្យការ</span>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                    </v-card-text>
                    <v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="documentDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :headers="documentHeader" :items="documentList">
                            <template v-slot:[`item.no`]="{ item, index }">
                                {{index+1}}
                            </template>
                            <template v-slot:[`item.caseLetterType`]="{ item }">
                                <span v-if="item.caseLetterType != null">
                                    {{item.caseLetterType.letterNameKh}}
                                </span>
                            </template>
                            <template v-slot:[`item.parties`]="{ item }">
                                <v-chip class="ma-1" v-if="item.parties.length > 0" v-for="party in item.parties" :key="party.id">
                                    {{party.nameKh}}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.lawyerInfo`]="{ item }">
                                <v-btn class="btn" small rounded outlined @click="viewLawyer(item)">
                                    {{$t('button.lawyerInfo')}}
                                </v-btn>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">

                                <v-icon medium color="primary" class="mr-2" @click="editDocument(item)">{{icons.mdiReceiptTextEdit}}</v-icon>

                            </template>
                        </v-data-table>
                        <v-dialog v-model="documentLawyerDialog" max-width="800">
                            <v-card min-height="400">
                                <v-toolbar flat>
                                    <v-card-title class="headline">
                                        <span class="primary--text-kh">ឈ្មោះមេធាវី</span>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="documentLawyerDialog = false">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text class="mt-5">
                                    <v-simple-table class="borderless-table elevation-1">
                                        <tr>
                                            <th>ឈ្មោះមេធាវី</th>
                                            <th>អត្តលេខ</th>
                                            <th>ការិយាល័យ</th>
                                            <th>លេខទូរស័ព្ទ</th>
                                            <th>ប្រភេទមេធាវី</th>
                                        </tr>
                                        <tr style="text-align:center" v-for="item in documentLawyerList" :key="item.id">
                                            <td style="padding: 10px">{{item.lawyerNameKh}}</td>
                                            <td style="padding: 10px">{{item.lawyerCardNo}}</td>
                                            <td style="padding: 10px">{{item.lawyerOffice}}</td>
                                            <td style="padding: 10px">{{item.lawyerPhone}}</td>
                                            <td style="padding: 10px">
                                                <span v-if="item.lawyerType">{{item.lawyerType.nameKh}}</span>
                                            </td>
                                        </tr>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>

                        </v-dialog>
                        <v-dialog v-model="documentDialog" transition="slide-x-transition" @input="onDocumentDialogClose">
                            <v-form ref="formDocument" lazy-validation>
                                <v-card class="right-side-dialog-800">
                                    <v-toolbar flat>
                                        <v-card-title>
                                            <span class="primary--text-kh">បញ្ចូលឯកសារមេធាវី</span>
                                        </v-card-title>
                                        <h4 class="h-subtitle">ដំណាក់កាលចៅក្រមជម្រះ</h4>
                                        <v-spacer></v-spacer>
                                        <v-btn text fab dark large color="primary" @click="onDocumentDialogClose">
                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-divider></v-divider>
                                    <v-card-text class="mt-5">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="lawyerDocument.documentNo" label="លេខលិខិត" outlined dense hide-details>
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="lawyerDocument.documentDate" label="កាលបរិច្ឆេទ" outlined dense type="date" hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-select v-model="lawyerDocument.caseLetterType" outlined :label="$t('select.letterType')" :items="getCaseLetterTypeByTypeId(1)" return-object :rules="rule.requiredField()" hide-details dense>
                                                    <template slot="selection" slot-scope="data">
                                                        <span v-if="$t('lang') == 'KH'">
                                                            {{data.item.letterNameKh}}
                                                        </span>
                                                        <span v-else>{{data.item.letterNameEn}}</span>
                                                    </template>
                                                    <template slot="item" slot-scope="data">
                                                        <span v-if="$t('lang') == 'KH'">
                                                            {{data.item.letterNameKh}}
                                                        </span>
                                                        <span v-else>{{data.item.letterNameEn}}</span>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-dialog v-model="lawyerListDialog" max-width="100%">
                                                <v-card flat style="min-height:300px">
                                                    <v-toolbar flat>
                                                        <v-card-title class="headline">
                                                            <span class="primary--text-kh">ភ្ជាប់ឈ្មោះមេធាវី</span>
                                                        </v-card-title>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text fab dark large color="primary" @click="lawyerListDialog=false">
                                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                                        </v-btn>
                                                    </v-toolbar>
                                                    <v-divider></v-divider>
                                                    <br />
                                                    <v-card-text>
                                                        <v-row v-for="(item, index) in lawyerData.lawyers" :key="item.id">
                                                            <v-col cols="12" md="2">
                                                                <v-text-field v-model="item.lawyerNameKh" label="ឈ្មោះមេធាវី" outlined dense hide-details :rules="rule.requiredField()"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <v-text-field v-model="item.lawyerCardNo" label="អត្តលេខ" outlined dense hide-details></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="3">
                                                                <v-text-field v-model="item.lawyerOffice" label="ការិយាល័យ " outlined dense hide-details></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <v-text-field v-model="item.lawyerPhone" label="លេខទូរស័ព្ទ" outlined dense hide-details></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <v-select v-model="item.lawyerType" label="ប្រភេទមេធាវី" outlined dense :items="caseLawyerTypes" item-text="nameKh" return-object hide-details>
                                                                    <template slot="selection" slot-scope="data">
                                                                        <span v-if="$t('lang') == 'KH'">
                                                                            {{data.item.nameKh}}
                                                                        </span>
                                                                        <span v-else>{{data.item.nameEn}}</span>
                                                                    </template>
                                                                    <template slot="item" slot-scope="data">
                                                                        <span v-if="$t('lang') == 'KH'">
                                                                            {{data.item.nameKh}}
                                                                        </span>
                                                                        <span v-else>{{data.item.nameEn}}</span>
                                                                    </template>
                                                                </v-select>
                                                            </v-col>
                                                            <v-col cols="12" sm="1">
                                                                <v-icon large color="primary" @click="removeLawyerItem(index)">{{icons.mdiDelete}}</v-icon>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="mt-5 justify-center">
                                                            <v-col cols="4" align="center">
                                                                <v-btn class="btn primary" @click="addLawyerItem($event)" small rounded>
                                                                    <v-icon small>{{icons.mdiPlus}}</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-dialog>
                                            <v-col cols="12" md="6">
                                                <v-btn class="primary--text" rounded outlined @click="lawyerListDialog=true">
                                                    <v-icon dark left>{{icons.mdiAttachment}}</v-icon> ភ្ជាប់ឈ្មោះមេធាវី
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-select v-model="lawyerDocument.parties" outlined label="ភ្ជាប់ឈ្មោះជនទាក់ទិន" :items="relatedPersonList" :item-text="getFieldText" dense return-object clearable chips multiple hide-details>
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip small>{{ item.nameKh }}</v-chip>
                                                    </template>
                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                            <v-list-item-action>
                                                                <v-checkbox :input-value="active"></v-checkbox>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-row no-gutters align="center">
                                                                        <span>{{ item.nameKh }}</span>
                                                                        <v-spacer></v-spacer>
                                                                        <v-chip small>
                                                                            {{ item.partyRole.roleNameKh }}
                                                                        </v-chip>
                                                                    </v-row>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="btn primary flex-grow-1" rounded @click="createDocument">{{$t('button.save')}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                    </v-card-text>
                </v-window-item>
                <v-window-item key="2">
                    <v-card-title>
                        <span class="primary--text">ជនទាក់ទិន</span>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="relatedPersonListDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                            <!--បន្ថែមជនទាក់ទិន-->
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :items="relatedPersonList" :headers="partyListHeader">
                            <template v-slot:[`item.no`]="{ item }">
                                {{relatedPersonList.indexOf(item)+1}}
                            </template>
                            <template v-slot:[`item.gender`]="{ item }">
                                <span v-if="item.gender == 1">ស្រី</span>
                                <span v-else>ប្រុស</span>
                            </template>
                            <template v-slot:[`item.partyRole`]="{ item }">
                                {{item.partyRole.roleNameKh}}
                            </template>
                            <template v-slot:[`item.hasLawyer`]="{ item }">
                                <span v-if="dPartyList.includes(Number(item.id))">
                                    <v-chip color="success" text-color="white" small class="font-weight-medium mr-3">មានមេធាវី </v-chip>
                                </span>
                                <span v-if="detainedPartyList.includes(Number(item.id))">
                                    <v-chip color="warning" text-color="white" small class="font-weight-medium">ជាប់ឃុំ </v-chip>
                                </span>
                            </template>
                        </v-data-table>
                        <v-dialog v-model="relatedPersonListDialog" transition="slide-x-transition" @input="onRelatedPartyClose">
                            <v-form ref="formParties" lazy-validation>
                                <v-card class="right-side-dialog-800">
                                    <v-toolbar flat>
                                        <v-card-title class="headline">
                                            <span class="primary--text-kh">{{$t('case.addRelatedPerson')}}</span>
                                        </v-card-title>
                                        <h4 class="h-subtitle">ដំណាក់កាលចៅក្រមជម្រះ</h4>
                                        <v-spacer></v-spacer>
                                        <v-btn text fab dark large color="primary" @click="onRelatedPartyClose">
                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-divider></v-divider>
                                    <v-card-text class="mt-5">
                                        <div v-for="(item,index) in relatedPersonList" :key="item.id">
                                            <v-row>
                                                <v-col cols="12" md="3">
                                                    <v-text-field v-model="item.nameKh" outlined hide-details :label="$t('case.relatedPersonName')" :rules="rule.requiredField()" dense>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-radio-group row v-model="item.gender" class="mt-0 ml-2">
                                                        <v-radio :label="$t('text.male')" :value=0></v-radio>
                                                        <v-radio :label="$t('text.female')" :value=1></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                    <v-text-field v-model="item.age" outlined hide-details :label="$t('text.age')" dense>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-select v-model="item.partyRole" :items="relatedPersonType" label="ជ្រើសរើសតួនាទីភាគី" return-object item-text="roleNameKh" dense hide-details outlined>
                                                        <template v-slot:selection="data">
                                                            {{data.item.roleNameKh}}
                                                        </template>
                                                        <template v-slot:item="data">
                                                            {{data.item.roleNameKh}}
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="1">
                                                    <v-icon class="mr-5" large color="primary" @click="removeRelatedPerson(index)">{{icons.mdiDelete}}</v-icon>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <v-row class="mt-5 justify-center">
                                            <v-col cols="4" align="center">
                                                <v-btn class="btn primary--text" outlined @click="addRelatedPerson($event)" small rounded>
                                                    <v-icon small>{{icons.mdiPlus}}</v-icon> More
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="btn primary flex-grow-1" rounded @click="createParties">{{$t('button.save')}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                    </v-card-text>
                </v-window-item>
                <v-window-item key="3">
                    <v-card-title>
                        <span class="primary--text">ភស្តុតាង</span>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="evidenceListDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                            <!--បន្ថែមជនទាក់ទិន-->
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :items="evidenceDocumentList" :headers="evidenceHeader">
                            <template v-slot:[`item.no`]="{ index, item }">
                                {{index+1}}
                            </template>
                            <template v-slot:[`item.deliveryStatus`]="{ item }">
                                <span v-if="item.deliveryStatus == 1">បានបញ្ជូន</span>
                                <span v-else>បានបញ្ជូន</span>
                            </template>
                            <template v-slot:[`item.parties`]="{ item }">
                                <v-chip class="ma-1" v-if="item.parties.length > 0" v-for="party in item.parties" :key="party.id">
                                    {{party.nameKh}}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.view`]="{ item }">

                                <v-icon medium color="primary" class="mr-2" @click="viewEvidence(item)">{{icons.mdiEye}}</v-icon>

                                <v-icon medium color="primary" class="mr-2" @click="editEvidence(item)">{{icons.mdiReceiptTextEdit}}</v-icon>

                            </template>
                        </v-data-table>
                        <v-dialog v-model="evidenceItemDialog" transition="slide-x-transition">
                            <v-card class="right-side-dialog-800">
                                <v-toolbar flat>
                                    <v-card-title class="headline">
                                        <span class="primary--text-kh">{{$t('case.evidenceList')}}</span>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="evidenceItemDialog=false">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text v-if="evidenceItems != null">
                                    <br />
                                    <v-simple-table class="bordered-table elevation-1">
                                        <tr>
                                            <th>ល.រ</th>
                                            <th>ប្រភេទវត្ថុ</th>
                                            <th>ឈ្មោះវត្ថុ</th>
                                            <th>ការពិពណ៌នា</th>
                                        </tr>
                                        <tr v-for="(item, index) in evidenceItems" :key="item.id">
                                            <td>{{index + 1}}</td>
                                            <td>{{item.evidenceType.evidenceTypeNameKh}}</td>
                                            <td>{{item.nameKh}}</td>
                                            <td>{{item.description}}</td>
                                        </tr>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="evidenceListDialog" transition="slide-x-transition" @input="onEvidenceDialogClose">
                            <v-form ref="formEvidenceList" lazy-validation>
                                <v-card class="right-side-dialog-800">
                                    <v-toolbar flat>
                                        <v-card-title class="headline">
                                            <span class="primary--text-kh">{{$t('case.addEvidence')}}</span>
                                        </v-card-title>
                                        <h4 class="h-subtitle">ដំណាក់កាលចៅក្រមជម្រះ</h4>
                                        <v-spacer></v-spacer>
                                        <v-btn text fab dark large color="primary" @click="onEvidenceDialogClose">
                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="evidenceDocument.documentNo" label="លេខដីកា" outlined dense hide-details :rules="rule.requiredField()">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="evidenceDocument.documentDate" label="កាលបរិច្ឆេទ" outlined dense hide-details type="date"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="3">
                                                <v-btn class="primary--text" rounded outlined @click="evidenceDialog=true">
                                                    <v-icon dark left>{{icons.mdiAttachment}}</v-icon> ភ្ជាប់ភស្តុតាង
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="9">
                                                <v-select v-model="evidenceDocument.parties" outlined label="ចាប់យកពីអ្នកណា (ជនទាក់ទិន)" :items="relatedPersonList" item-text="nameKh" dense return-object clearable chips multiple rounded hide-details>
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip small>{{ item.nameKh }}</v-chip>
                                                    </template>
                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                            <v-list-item-action>
                                                                <v-checkbox :input-value="active"></v-checkbox>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-row no-gutters align="center">
                                                                        <span>{{ item.nameKh }}</span>
                                                                        <v-spacer></v-spacer>
                                                                        <v-chip small>{{ item.partyRole.roleNameKh }}</v-chip>
                                                                    </v-row>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-radio-group row v-model="evidenceDocument.deliveryStatus" class="mt-0 ml-2">
                                                    <v-radio label="បានបញ្ជូន" :value=1></v-radio>
                                                    <v-radio label="មិនបានបញ្ជូន" :value=0></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="btn primary flex-grow-1" color="primary" rounded @click="createEvidenceDocument">{{$t('button.save')}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                        <v-dialog v-model="evidenceDialog" max-width="80%">
                            <v-card style="min-height:300px">
                                <v-toolbar flat>
                                    <v-toolbar-title>
                                        <span class="primary--text-kh">ភ្ជាប់វត្ថុតាង</span>
                                    </v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="evidenceDialog=false">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text class="mt-5">
                                    <v-row v-for="(item, index) in evidenceData.evidenceList" :key="item.id">
                                        <v-col cols="12" md="3">
                                            <v-select v-model="item.evidenceType" :items="caseEvidenceTypeList" outlined dense hide-details return-object label="ប្រភេទភស្តុតាង">
                                                <template v-slot:selection="data">
                                                    {{data.item.evidenceTypeNameKh}}
                                                </template>
                                                <template v-slot:item="data">
                                                    {{data.item.evidenceTypeNameKh}}
                                                </template>

                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="item.nameKh" label="ឈ្មោះភស្តុតាង" outlined dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="item.description" label="ការពណ៍នានៃភស្តុតាង" outlined dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-icon class="mr-5" large color="primary" @click="removeEvidenceItem(index)">{{icons.mdiDelete}}</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="flex text-center justify-center">
                                    <v-btn class="btn primary" @click="addEvidenceItem($event)" small rounded>
                                        <v-icon small>{{icons.mdiPlus}}</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-card-text>
                </v-window-item>
                <v-window-item key="4">
                    <v-card-text>
                        <br />
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-card-title class="printIcon">
                                        <span class="primary--text-kh">ប្រវត្តិកិច្ចការបានអនុវត្ត</span>
                                    </v-card-title>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card-text>
                                        <v-data-table class="borderless-table elevation-1" :headers="taskListHistoryHeader" :items="taskHistoryList">
                                            <template v-slot:[`item.no`]="{ item }">
                                                {{taskHistoryList.indexOf(item)+1}}
                                            </template>
                                            <template v-slot:[`item.taskType`]="{ item }">
                                                <span v-if="item.taskType"> {{item.taskType.taskTypeKh}} </span>
                                            </template>
                                            <template v-slot:[`item.parties`]="{ item }">
                                                <v-chip class="ma-1" v-for="party in item.parties" :key="party.id">
                                                    {{party.nameKh}}
                                                </v-chip>
                                            </template>
                                            <template v-slot:[`item.phase`]="{ item }">
                                                <span v-if="item.phase == 2">
                                                    ចៅក្រមស៊ើប
                                                </span>
                                                <span v-else>អយ្យការ</span>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                    <v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="taskDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :headers="taskListHeader" :items="caseTaskList">
                            <template v-slot:[`item.no`]="{ index, item }">
                                {{index+1}}
                            </template>
                            <template v-slot:[`item.taskType`]="{ item }">
                                <span v-if="item.taskType"> {{item.taskType.taskTypeKh}} </span>
                            </template>
                            <template v-slot:[`item.parties`]="{ item }">
                                <v-chip class="ma-1" v-if="item.parties.length > 0" v-for="party in item.parties" :key="party.id">
                                    {{party.nameKh}}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon medium color="primary" class="mr-2" @click="editTaskDocument(item)">{{icons.mdiReceiptTextEdit}}</v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-dialog v-model="taskDialog" transition="slide-x-transition" @input="onTaskDialogClose">
                        <v-form ref="formTaskList" lazy-validation>
                            <v-card class="right-side-dialog-800">
                                <v-toolbar flat>
                                    <v-card-title class="headline">
                                        <span class="primary--text-kh">កិច្ចការបានអនុវត្ត</span>
                                    </v-card-title>
                                    <h4 class="h-subtitle">ដំណាក់កាលចៅក្រមជម្រះ</h4>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="onTaskDialogClose">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="taskDocument.documentNo" label="លេខឯកសារ" outlined dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="taskDocument.documentDate" label="កាលបរិច្ឆេទ" outlined dense type="date" hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-select v-model="taskDocument.taskType" outlined label="ប្រភេទកិច្ចការ" :items="taskTypeList" return-object :rules="rule.requiredField()" hide-details dense>
                                                <template slot="selection" slot-scope="data">
                                                    <span v-if="$t('lang') == 'KH'">
                                                        {{data.item.taskTypeKh}}
                                                    </span>
                                                    <span v-else>{{data.item.taskTypeEn}}</span>
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    <span v-if="$t('lang') == 'KH'">
                                                        {{data.item.taskTypeKh}}
                                                    </span>
                                                    <span v-else>{{data.item.taskTypeEn}}</span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select v-model="taskDocument.parties" outlined label="ភ្ជាប់ឈ្មោះជនទាក់ទិន" :items="relatedPersonList" item-text="nameKh" return-object dense clearable chips multiple hide-details>
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip small>{{ item.nameKh }}</v-chip>
                                                </template>
                                                <template v-slot:item="{ active, item, attrs, on }">
                                                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                        <v-list-item-action>
                                                            <v-checkbox :input-value="active"></v-checkbox>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <v-row no-gutters align="center">
                                                                    <span>{{ item.nameKh }}</span>
                                                                    <v-spacer></v-spacer>
                                                                    <v-chip small>{{ item.partyRole.roleNameKh }}</v-chip>
                                                                </v-row>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea rows="4" auto-grow outlined v-model="taskDocument.description" label="ខ្លឹមសារសង្ខេប"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="btn primary flex-grow-1" rounded @click="createTask">{{$t('button.save')}}</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </v-window-item>
                <v-window-item key="5">
                    <br />
                    <v-card-text>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-card-title class="printIcon">
                                        <span class="primary--text-kh">ប្រវត្តិសេចក្តីសម្រេច</span>
                                    </v-card-title>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card-text>
                                        <v-data-table class="borderless-table elevation-1" :headers="decisionHistoryHeader" :items="decisionDocumentHistoryList">
                                            <template v-slot:[`item.no`]="{ item, index }">
                                                {{index+1}}
                                            </template>
                                            <template v-slot:[`item.decisionType`]="{ item }">
                                                {{item.decisionType.decisionTypeNameKh}}
                                            </template>
                                            <template v-slot:[`item.viewDecision`]="{ item }">
                                                <v-btn class="btn" rounded outlined @click="showJudgement(item)">ជនជាប់ចោទ</v-btn>
                                            </template>

                                            <template v-slot:[`item.isAppealed`]="{ item }">
                                                <span v-if="item.isAppealed == 1">មាន</span>
                                                <span v-else>មិនមាន</span>
                                            </template>
                                            <template v-slot:[`item.phase`]="{ item }">
                                                <span v-if="item.phase == 2">
                                                    ចៅក្រមស៊ើប
                                                </span>
                                                <span v-else>អយ្យការ</span>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                    <v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="decisionDocumentDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :items="decisionDocumentList" :headers="decisionDocumentHeader">
                            <template v-slot:[`item.no`]="{ index, item }">
                                {{index+1}}
                            </template>
                            <template v-slot:[`item.isAppealed`]="{ item }">
                                <span v-if="item.isAppealed == 1">មាន</span>
                                <span v-else>មិនមាន</span>
                            </template>
                            <template v-slot:[`item.decisionType`]="{ item }">
                                {{item.decisionType.decisionTypeNameKh}}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon medium color="primary" class="mr-2" @click="showJudgement(item)">{{icons.mdiEye}}</v-icon>
                                <v-icon medium color="primary" class="mr-2" @click="editDecisionDocument(item)">{{icons.mdiReceiptTextEdit}}</v-icon>
                            </template>
                        </v-data-table>
                        <v-dialog v-model="viewJudgementDialog" transition="slide-x-transition" @input="onViewJudgementDialogClose">
                            <v-card class="right-side-dialog-800">
                                <v-toolbar flat>
                                    <v-card-title class="headline">
                                        <span class="primary--text-kh">សម្រេចចោទប្រកាន់</span>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="onViewJudgementDialogClose">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text class="mt-5">
                                    <v-simple-table v-if="displayJudgement != null" class="bordered-table elevation-1">
                                        <tr>
                                            <td>ឈ្មោះជនជាប់ចោទ</td>
                                            <td v-if="displayJudgement.party">{{displayJudgement.party.nameKh}}</td>
                                        </tr>
                                        <tr>
                                            <td>បទល្មើសនៃការចោទ</td>
                                            <td>
                                                <span v-for="item in displayJudgement.crimeTypes" :key="item.id">
                                                    <v-chip class="mr-3"> {{item.crimeTypeNameKh}} </v-chip>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>ស្ថានភាព</td>
                                            <td>
                                                <span v-if="displayJudgement.detentionStatus === 1">ជាប់ឃុំ</span>
                                                <span v-if="displayJudgement.detentionStatus === 2">ដោះលេង</span>
                                                <span v-if="displayJudgement.detentionStatus === 3">នៅក្រៅឃុំ</span>
                                                <span v-if="displayJudgement.detentionStatus === 4">រត់គេចខ្លួន</span>
                                            </td>
                                        </tr>
                                        <tr v-if="displayJudgement.detentionStatus === 1">
                                            <td>កាលបរិច្ឆេទឃុំខ្លួន</td>
                                            <td>{{displayJudgement.detentionDate}}</td>
                                        </tr>
                                        <tr v-if="displayJudgement.detentionStatus === 1">
                                            <td>រយៈពេលឃុំខ្លួន</td>
                                            <td>ចំនួនខែ៖
                                                <v-chip>{{displayJudgement.detentionMonth}} ខែ</v-chip> ចំនួនថ្ងៃ៖
                                                <v-chip>{{displayJudgement.detentionDay}} ថ្ងៃ</v-chip>
                                            </td>
                                        </tr>
                                        <tr v-if="displayJudgement.detentionStatus === 2">
                                            <td>កាលបរិច្ឆេទដោះលេង</td>
                                            <td>{{displayJudgement.detentionMonth}}</td>
                                        </tr>

                                        <!-- <tr style="text-align:center" v-for="item in chargePersonList" :key="item.id">
                                            <td v-if="index >= 1 && (item.party[index].id != item.party[index - 1].id)"></td>
                                            <td>ឈ្មោះជនជាប់ចោទ</td>
                                            <td>{{item.party.nameKh}}</td>
                                            <td>{{item.crimeType.crimeTypeNameKh}}</td>
                                        </tr> -->
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="decisionDocumentDialog" transition="slide-x-transition" @input="onDecisionDocumentDialogClose">
                            <v-form ref="formDecisionDocumentList" lazy-validation>
                                <v-card class="right-side-dialog-800">
                                    <v-toolbar flat>
                                        <v-card-title class="headline">
                                            <span class="primary--text-kh">{{$t('case.addDecision')}}</span>
                                        </v-card-title>
                                        <h4 class="h-subtitle">ដំណាក់កាលចៅក្រមជម្រះ</h4>
                                        <v-spacer></v-spacer>
                                        <v-btn text fab dark large color="primary" @click="onDecisionDocumentDialogClose">
                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="decisionDocument.decisionRef" label="លេខលិខិត" outlined dense hide-details>
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="decisionDocument.decisionDate" label="កាលបរិច្ឆេទ" outlined dense type="date" hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-select v-model="decisionDocument.decisionType" :items="caseDecisionTypeList" :rules="rule.requiredField()" return-object item-text="decisionTypeNameKh" label="ប្រភេទសេចក្តីសម្រេច" hide-details outlined dense>
                                                    <template v-slot:selection="{ item, index }">
                                                        {{ item.decisionTypeNameKh }}
                                                    </template>
                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-row no-gutters align="center" style="padding: 5px">
                                                                        <span>{{ item.decisionTypeNameKh }}</span>
                                                                    </v-row>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="decisionDocument.decisionNote" label="ការសម្រេចដូចម្តេច" outlined dense hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-radio-group row v-model="decisionDocument.isAppealed" class="mt-0 ml-2">
                                                    <v-radio label="មានបណ្តឹងឧទ្ធរណ៍" :value="true"></v-radio>
                                                    <v-radio label="មិនមាន" :value="false"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <v-dialog v-model="chargeDialog" max-width="100%">
                                                <v-card class="right-side-dialog-750">
                                                    <v-toolbar flat>
                                                        <v-card-title class="headline">
                                                            <span class="primary--text-kh">សម្រេច</span>
                                                        </v-card-title>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text fab dark large color="primary" @click="onChargeDialogClose">
                                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                                        </v-btn>
                                                    </v-toolbar>
                                                    <v-divider></v-divider>
                                                    <br />
                                                    <v-card-text>
                                                        <!--
                                                        <div v-for="(item, index) in chargePersonList" :key="item.id">
                                                            <v-row>
                                                                <v-col cols="12" md="5">
                                                                    <v-select v-model="item.party" :items="relatedPersonList" item-text="nameKh" return-object label="ជ្រើសរើសអ្នកសម្រេចត្រូវចោទ" hide-details outlined>
                                                                        <template v-slot:selection="{ item, index }">
                                                                            {{ item.nameKh }}
                                                                        </template>
                                                                        <template v-slot:item="{ active, item, attrs, on }">
                                                                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title>
                                                                                        <v-row no-gutters align="center">
                                                                                            <span>{{ item.nameKh }}</span>
                                                                                            <v-spacer></v-spacer>
                                                                                            <v-chip small>{{ item.partyRole.roleNameKh }}</v-chip>
                                                                                        </v-row>
                                                                                    </v-list-item-title>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col cols="12" md="7">
                                                                    <v-autocomplete v-model="item.crimeType" item-text="crimeTypeNameKh" :rules="rule.requiredField()" :items="caseCrimeTypeList" label="ជ្រើសរើសបទល្មើសនៃការចោទ" return-object outlined hide-details multiple>
                                                                        <template v-slot:selection="{ item, index }">
                                                                            <v-chip small>{{ item.crimeTypeNameKh }}</v-chip>
                                                                        </template>
                                                                        <template v-slot:item="{ active, item, attrs, on }">
                                                                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                                                <v-list-item-action>
                                                                                    <v-checkbox :input-value="active"></v-checkbox>
                                                                                </v-list-item-action>
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title>
                                                                                        <v-row no-gutters align="center">
                                                                                            <span>{{ item.crimeTypeNameKh }}</span>
                                                                                        </v-row>
                                                                                    </v-list-item-title>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </template>
                                                                    </v-autocomplete>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-checkbox label="ជាប់ឃុំ" v-model="item.detentionChecked"></v-checkbox>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" md="4">
                                                                    <v-text-field v-model="item.detentionDate" label="កាលបរិច្ឆេទឃុំខ្លួន" outlined dense type="date" :disabled="!item.detentionChecked" hide-details></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" md="4">
                                                                    <v-text-field v-model="item.detentionPeriod" label="រយៈពេលឃុំខ្លួន" outlined dense hide-details :disabled="!item.detentionChecked"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="12" md="4">
                                                                    <v-text-field v-model="item.detentionDelay1" label="រយៈពេលពន្យារលើកទី១" outlined dense hide-details :disabled="!item.detentionChecked"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" md="4">
                                                                    <v-text-field v-model="item.detentionDelay2" label="រយៈពេលពន្យារលើកទី២" outlined dense hide-details :disabled="!item.detentionChecked"></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-divider class="my-5"></v-divider>
                                                        </div>
                                                        -->
                                                        <v-row>
                                                            <v-col cols="12" md="5">
                                                                <v-select v-model="judgement.party" :items="relatedPersonList" item-text="nameKh" :rules="rule.requiredField()" @change="selectPartyForJudgement()" return-object label="ជ្រើសរើសអ្នកសម្រេចត្រូវចោទ" hide-details outlined>
                                                                    <template v-slot:selection="{ item, index }">
                                                                        {{ item.nameKh }}
                                                                    </template>
                                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                                            <v-list-item-content>
                                                                                <v-list-item-title>
                                                                                    <v-row no-gutters align="center">
                                                                                        <span>{{ item.nameKh }}</span>
                                                                                        <v-spacer></v-spacer>
                                                                                        <v-chip small>{{ item.nameKh }}</v-chip>
                                                                                    </v-row>
                                                                                </v-list-item-title>
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                    </template>
                                                                </v-select>
                                                            </v-col>
                                                            <v-col cols="12" md="7">
                                                                <v-autocomplete v-model="judgement.crimeType" item-text="crimeTypeNameKh" :rules="rule.requiredField()" :items="caseCrimeTypeList" label="ជ្រើសរើសបទល្មើសនៃការចោទ" return-object outlined hide-details multiple>
                                                                    <template v-slot:selection="{ item, index }">
                                                                        <v-chip small>{{ item.crimeTypeNameKh }}</v-chip>
                                                                    </template>
                                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                                            <v-list-item-action>
                                                                                <v-checkbox :input-value="active"></v-checkbox>
                                                                            </v-list-item-action>
                                                                            <v-list-item-content>
                                                                                <v-list-item-title>
                                                                                    <v-row no-gutters align="center">
                                                                                        <span>{{ item.crimeTypeNameKh }}</span>
                                                                                    </v-row>
                                                                                </v-list-item-title>
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                    </template>
                                                                </v-autocomplete>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="justify-center">
                                                            <v-col cols="6">
                                                                <!--   <v-checkbox label="ជាប់ឃុំ" v-model="judgement.detentionChecked"></v-checkbox> -->
                                                                <v-select outlined dense label="ស្ថានភាពជនទាក់ទិន" v-model="judgement.detentionStatus" :items="partyStatus" @change="selectPartyStatus"></v-select>
                                                            </v-col>
                                                        </v-row>
                                                        <v-card class="mx-auto" width="90%">
                                                            <v-card-title v-if="selectedPartyStatus === 1">
                                                                <span class="font-weight-black">ការឃុំខ្លួន</span>
                                                            </v-card-title>
                                                            <v-card-title v-else-if="selectedPartyStatus === 2">
                                                                <span class="font-weight-black">ការដោះលេង</span>
                                                            </v-card-title>
                                                            <v-card-text class="pt-4" v-if="selectedPartyStatus === 1">
                                                                <v-row>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field v-model="judgement.detentionDate" label="កាលបរិច្ឆេទឃុំខ្លួន" outlined dense type="date" hide-details></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="3">
                                                                        <v-text-field v-model="judgement.detentionMonth" label="ចំនួនខែ" outlined dense hide-details></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="3">
                                                                        <v-text-field v-model="judgement.detentionDay" label="ចំនួនថ្ងៃ" outlined dense hide-details></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field v-model="judgement.detentionDelay1" label="រយៈពេលពន្យារលើកទី១" outlined dense hide-details></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field v-model="judgement.detentionDelay2" label="រយៈពេលពន្យារលើកទី២" outlined dense hide-details></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                            <v-card-text class="pt-4" v-else-if="selectedPartyStatus === 2">
                                                                <v-row>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field v-model="judgement.releaseDate" label="កាលបរិច្ឆេទដោះលេង" outlined dense type="date" hide-details></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                        <v-divider class="my-5"></v-divider>
                                                    </v-card-text>
                                                    <v-card-actions class="flex text-center justify-center">
                                                        <v-btn class="btn primary" @click="onChargeDialogClose" rounded>
                                                            បិទ
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            <v-col cols="12" md="6">
                                                <v-btn class="primary--text" rounded outlined @click="openChargeDialog">
                                                    <v-icon dark left>{{icons.mdiAttachment}}</v-icon> ភ្ជាប់ឈ្មោះជនទាក់ទិន
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="btn primary flex-grow-1" rounded @click="createDecision">{{$t('button.save')}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>

                            </v-form>

                        </v-dialog>
                    </v-card-text>
                </v-window-item>
            </v-window>
        </div>
    </div>
</template>

<script>
import {
  mdiCalendarAlert,
  mdiPlus,
  mdiAttachment,
  mdiContentSave,
  mdiDelete,
  mdiClose,
  mdiEye,
  mdiEyeCircle,
  mdiFileEdit,
  mdiReceiptTextEdit,
  mdiHistory
} from '@mdi/js'
import SystemService from '@/services/service.system'
import CaseService from '@/services/service.case'
import StaffService from '@/services/service.staff'
import Rule from '@/plugins/rules.js'
import { clearTimeout, setTimeout } from 'timers'

export default {
  props: {
    data: Object
  },
  name: 'trial-phase-section',
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiAttachment,
        mdiContentSave,
        mdiDelete,
        mdiClose,
        mdiEye,
        mdiEyeCircle,
        mdiFileEdit,
        mdiReceiptTextEdit,
        mdiHistory
      },
      rule: '',
      prosTab: 0,
      taskDocument: {},
      taskTypeList: [],
      isProsecutorAppointed: false,
      appointmentDialog: false,
      warrantDialog: false,
      documentDialog: false,
      documentLawyerDialog: false,
      warrantHistoryDialog: false,
      taskDialog: false,
      editedDecisionIndex: -1,
      editedWarrantIndex: -1,
      editedDocumentIndex: -1,
      editedEvidenceDocumentIndex: -1,
      editedTaskIndex: -1,
      documentLawyerList: [],
      appointment: {},
      appointmentList: [],
      documentList: [],
      documentHistoryList: [],
      lawyerDocument: {},
      lawyerData: { lawyers: [] },
      warrantData: {},
      warrantList: [],
      warrantHistoryList: [],
      taskHistoryList: [],
      verdictData: {},
      decisionDocument: {},
      decisionDocumentList: [],
      decisionDocumentHistoryList: [],
      evidenceDocument: {},
      evidenceData: { evidenceList: [] },
      clerkName: '',
      evidenceDocumentList: [],
      caseLetterTypeList: [],
      lawyerListDialog: false,
      evidenceDialog: false,
      chargeDialog: false,
      evidenceListDialog: false,
      evidenceItemDialog: false,
      decisionDocumentDialog: false,
      viewJudgementDialog: false,
      evidenceItems: [],
      chargePersonList: [{ party: {}, crimeType: [] }],
      caseCrimeTypeList: [],
      caseDecisionTypeList: [],
      criminalCase: {},
      relatedPersonList: [],
      dPartyList: [],
      detainedPartyList: [],
      relatedPersonType: [],
      relatedPersonListDialog: false,
      caseEvidenceTypeList: [],
      judgeList: [],
      judgement: { party: '', crimeType: [] },
      appointedJudgeList: [],
      additionalJudges: [],
      judgeRoles: [],
      caseLawyerTypes: [],
      caseTaskList: [],
      resultMessage: '',
      expandedPanels: [],
      isUpdating: false,
      timeout: null,
      selectedPartyStatus: '',
      displayJudgement: {},
      warrantHistoryHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'warrantLetterNo' },
        { text: 'ដីការចេញ', value: 'warrantDate' },
        //   { text: 'បង្ហាញខ្លួន', value: 'appearanceDate' },
        { text: 'អ្នកទទួលបន្ទុក', value: 'inCharge' },
        { text: 'ដំណាក់កាល', value: 'phase' }
        //{ text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' }
      ],
      warrantHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'warrantLetterNo' },
        { text: 'ដីការចេញ', value: 'warrantDate' },
        { text: 'បង្ហាញខ្លួន', value: 'appearanceDate' },
        { text: 'ចៅក្រម', value: 'caller' },
        { text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' },
        { text: 'កែប្រែ', value: 'actions' }
      ],
      appointmentHeader: [
        { text: 'លេខលិខិត', value: 'letterNo' },
        { text: 'កាលបរិច្ឆេទ', value: 'appointmentDate' },
        { text: 'ចៅក្រមស៊ើប', value: 'judgeNameKh' },
        { text: 'តួនាទី', value: 'roleNameKh' }
      ],
      partyListHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'ឈ្មោះ', value: 'nameKh' },
        { text: 'ភេទ', value: 'gender' },
        { text: 'អាយុ', value: 'age' },
        { text: 'ភាគី', value: 'partyRole' },
        { text: 'មានមេធាវី', value: 'hasLawyer' }
      ],
      documentHistoryHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'documentNo' },
        { text: 'កាលបរិច្ឆេទ', value: 'documentDate' },
        { text: 'ប្រភេទលិខិត', value: 'caseLetterType' },
        { text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' },
        { text: 'ព័ត៍មានមេធាវី', value: 'lawyerInfo' },
        { text: 'ដំណាក់កាល', value: 'phase' }
      ],
      documentHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'documentNo' },
        { text: 'កាលបរិច្ឆេទ', value: 'documentDate' },
        { text: 'ប្រភេទលិខិត', value: 'caseLetterType' },
        { text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' },
        { text: 'ព័ត៍មានមេធាវី', value: 'lawyerInfo' },
        { text: 'កែប្រែ', value: 'actions' }
      ],
      evidenceHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'ប្រភេទ', value: 'documentNo' },
        { text: 'ឈ្មោះ', value: 'documentDate' },
        { text: 'ស្ថានភាព', value: 'deliveryStatus' },
        { text: 'ភាគីដែលចាប់យក', value: 'parties' },
        { text: 'ភ្ជាប់ភស្តុតាង', value: 'view' }
      ],
      taskListHistoryHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខឯកសារ', value: 'documentNo' },
        { text: 'កាលបរិច្ឆេទ', value: 'documentDate' },
        { text: 'ប្រភេទកិច្ចការ', value: 'taskType' },
        { text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' },
        { text: 'ដំណាក់កាល', value: 'phase' }
      ],
      decisionHistoryHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'decisionRef' },
        { text: 'កាលបរិច្ឆេទ', value: 'decisionDate' },
        { text: 'ប្រភេទការសម្រេច', value: 'decisionType' },
        { text: 'សេចក្តីសម្រេច', value: 'viewDecision' },
        { text: 'បណ្តឹងឧទ្ធរណ៍', value: 'isAppealed' },
        { text: 'ដំណាក់កាល', value: 'phase' }
      ],
      decisionDocumentHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'decisionRef' },
        { text: 'កាលបរិច្ឆេទ', value: 'decisionDate' },
        { text: 'ប្រភេទការសម្រេច', value: 'decisionType' },
        { text: 'បណ្តឹងឧទ្ធរណ៍', value: 'isAppealed' },
        { text: 'កែប្រែ', value: 'actions' }
      ],
      taskListHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខឯកសារ', value: 'documentNo' },
        { text: 'កាលបរិច្ឆេទ', value: 'documentDate' },
        { text: 'ប្រភេទកិច្ចការ', value: 'taskType' },
        { text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' },
        { text: 'កែប្រែ', value: 'actions' }
      ],
      partyStatus: [
        { text: 'ជាប់ឃុំ', value: 1 },
        { text: 'ដោះលេង', value: 2 },
        { text: 'នៅក្រៅឃុំ', value: 3 },
        { text: 'រត់គេចខ្លួន', value: 4 }
      ]
    }
  },
  watch: {
    isUpdating(val) {
      clearTimeout(this.timeout)

      if (val) {
        this.timeout = setTimeout(() => (this.isUpdating = false), 3000)
      }
    }
  },
  async created() {
    this.rule = Rule

    if (this.$route.params.id) {
      var caseId = this.$route.params.id

      this.clerkName = JSON.parse(localStorage.getItem('user')).fullname

      await CaseService.getCaseById(caseId).then(response => {
        this.criminalCase = response.data
      })

      await CaseService.getPartyByCaseId(caseId).then(response => {
        this.relatedPersonList = response.data
      })

      await CaseService.getTrialAppointmentByCaseId(caseId).then(response => {
        this.appointmentList = response.data
      })

      await CaseService.getTrialWarrantByCaseId(caseId).then(response => {
        this.warrantList = response.data
      })

      await CaseService.getAllWarrantHistoryByCaseId(caseId).then(response => {
        this.warrantHistoryList = response.data
      })

      await CaseService.getTrialLawyerDocumentByCaseId(caseId).then(
        response => {
          this.documentList = response.data
        }
      )

      await CaseService.getLawyerDocumentHistoryByCaseId(caseId).then(
        response => {
          this.documentHistoryList = response.data
        }
      )

      await CaseService.getEvidenceDocumentByCaseId(caseId).then(response => {
        this.evidenceDocumentList = response.data
      })

      await CaseService.getDecisionDocumentHistoryByCaseId(caseId).then(
        response => {
          this.decisionDocumentHistoryList = response.data
        }
      )

      await CaseService.getTrialDecisionDocumentByCaseId(caseId).then(
        response => {
          this.decisionDocumentList = response.data
        }
      )

      await CaseService.getTaskDocumentHistoryByCaseId(caseId).then(
        response => {
          this.taskHistoryList = response.data
        }
      )

      await CaseService.getTrialTaskDocumentByCaseId(caseId).then(response => {
        this.caseTaskList = response.data
      })

      await StaffService.getStaffListByPosition(3).then(response => {
        this.judgeList = response.data
      })

      await CaseService.getDefendedPartyByCaseId(this.criminalCase.id).then(
        response => {
          this.dPartyList = response.data
        }
      )

      await CaseService.getDetainedPartyByCaseId(this.criminalCase.id).then(
        response => {
          this.detainedPartyList = response.data
        }
      )

      await CaseService.getTrialAppointmentByCaseIdAndPhase(caseId).then(
        response => {
          if (response.data) {
            response.data.forEach(element => {
              this.appointedJudgeList.push(element.staff)
            })

            this.appointedJudgeList = this.appointedJudgeList.filter(
              (item, index, self) =>
                index === self.findIndex(t => t.id === item.id)
            )
          }
        }
      )
    }

    CaseService.getCasePartyRoleAll().then(response => {
      this.relatedPersonType = response.data
    })

    SystemService.getCaseLetterTypeAll().then(response => {
      this.caseLetterTypeList = response.data
    })

    SystemService.getCaseEvidenceTypeAll().then(response => {
      this.caseEvidenceTypeList = response.data
    })

    SystemService.getTrialCaseDecisionType().then(response => {
      this.caseDecisionTypeList = response.data
    })

    SystemService.getLawyerTypeAll().then(response => {
      this.caseLawyerTypes = response.data
    })

    SystemService.getCaseTaskTypeAll().then(response => {
      this.taskTypeList = response.data
      //  alert(JSON.stringify(this.taskTypeList ))
    })

    SystemService.getJudgeRoleAll().then(response => {
      this.judgeRoles = response.data
    })
  },
  watch: {
    relatedPersonList: function(val) {
      this.$emit('updateProsData', val)
    }
  },
  methods: {
    addAppointedTrial(event) {
      this.additionalJudges.push({})
    },
    removeJudge(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + (index + 1),
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.additionalJudges.splice(index, 1)
          }
        }
      })
    },
    async createAppointment() {
      if (!this.$refs.formAppointment.validate()) return

      if (this.criminalCase == null) return

      var appBatch = []
      /*
      this.appointment.criminalData = this.criminalCase
      this.appointment.clerkName = this.clerkName
      */

      appBatch.push({
        appointmentDate: this.appointment.appointmentDate,
        letterNo: this.appointment.letterNo,
        criminalData: this.criminalCase,
        clerkName: this.clerkName,
        staff: this.appointment.staff,
        judRoleId: this.appointment.judRoleId
      })

      if (this.additionalJudges.length > 0) {
        this.additionalJudges.forEach(element => {
          if (element.staff != null && element.judRoleId != null) {
            appBatch.push({
              appointmentDate: this.appointment.appointmentDate,
              letterNo: this.appointment.letterNo,
              criminalData: this.criminalCase,
              clerkName: this.clerkName,
              staff: element.staff,
              judRoleId: element.judRoleId
            })
          }
        })
      }

      var successCount = 0

      appBatch.forEach(element => {
        CaseService.insertTrialAppointment(element).then(response => {
          if (response.data) {
            successCount = successCount + 1
          }
        })
      })

      //  alert(successCount)

      this.$notify({
        group: 'message',
        title: 'Notification',
        type: 'success',
        text: 'ការចាត់តាំងត្រូវបានបញ្ចូល'
      })
      this.refreshAppointmentList(this.criminalCase.id)
      this.additionalJudges = []
      this.appointment = {}
      this.appointmentDialog = false
    },
    async refreshAppointmentList(caseId) {
      await CaseService.getTrialAppointmentByCaseId(caseId).then(response => {
        this.appointmentList = response.data
      })
    },
    createWarrant() {
      if (!this.$refs.formWarrant.validate()) return
      if (this.criminalCase == null) return

      this.warrantData.criminalData = this.criminalCase
      this.warrantData.clerkName = this.clerkName

      CaseService.insertTrialWarrant(this.warrantData).then(
        response => {
          if (response.data) {
            this.resultMessage = 'Warrant has been submitted'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })
            if (this.editedWarrantIndex > -1) {
              Object.assign(
                this.warrantList[this.editedWarrantIndex],
                this.warrantData
              )
            } else {
              this.warrantData.id = response.data.id
              this.warrantList.push(this.warrantData)
            }
            this.editedWarrantIndex = -1
            this.warrantData = {}
            this.warrantDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    async openWarrantDialog() {
      this.warrantDialog = true
    },
    editWarrant(item) {
      this.editedWarrantIndex = this.warrantList.indexOf(item)
      this.warrantData = Object.assign({}, item)
      this.warrantDialog = true
    },

    onWarrantDialogClose() {
      this.editedWarrantIndex = -1
      this.warrantData = {}
      this.warrantDialog = false
    },
    async onRelatedPartyClose() {
      if (this.$route.params.id) {
        //  alert('hi')
        var caseId = this.$route.params.id

        await CaseService.getPartyByCaseId(caseId).then(response => {
          this.relatedPersonList = response.data
        })
        this.relatedPersonListDialog = false
      }
    },
    createParties() {
      if (!this.$refs.formParties.validate()) return

      CaseService.insertPartyBatch(this.relatedPersonList).then(
        response => {
          if (response.data && response.data.message == 'created') {
            this.resultMessage = 'The party list has been updated'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })
            this.relatedPersonListDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    createDocument() {
      if (!this.$refs.formDocument.validate()) return
      if (this.criminalCase == null) return

      this.lawyerDocument.criminalData = this.criminalCase

      CaseService.insertTrialLawyerDocument(this.lawyerDocument).then(
        response => {
          if (response.data) {
            this.resultMessage = 'Lawyer document has been submitted'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            this.lawyerData.lawyers = this.lawyerData.lawyers.map(v => ({
              ...v,
              caseLawyerDocument: response.data
            }))

            CaseService.insertLawyerDocumentAssignmentBatch(
              this.lawyerData.lawyers
            )

            if (this.editedDocumentIndex > -1) {
              Object.assign(
                this.documentList[this.editedDocumentIndex],
                this.lawyerDocument
              )
            } else {
              this.lawyerDocument.id = response.data.id
              this.documentList.push(this.lawyerDocument)
            }

            this.editedDocumentIndex = -1
            this.lawyerDocument = {}
            this.lawyerData.lawyers = []
            this.documentDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    async editDocument(item) {
      this.editedDocumentIndex = this.documentList.indexOf(item)
      this.lawyerDocument = Object.assign({}, item)
      var documentId = item.id

      await CaseService.getLawyerDocumentAssignmentByDocumentId(
        documentId
      ).then(response => {
        this.lawyerData.lawyers = response.data
      })
      this.documentDialog = true
    },
    onDocumentDialogClose() {
      this.editedDocumentIndex = -1
      this.lawyerDocument = {}
      this.lawyerData.lawyers = []
      this.documentDialog = false
    },
    createEvidenceDocument() {
      if (!this.$refs.formEvidenceList.validate()) return
      if (this.criminalCase == null) return

      this.evidenceDocument.criminalData = this.criminalCase

      CaseService.insertEvidenceDocument(this.evidenceDocument).then(
        response => {
          if (response.data) {
            this.resultMessage = 'Evidence document has been submitted'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            this.evidenceData.evidenceList = this.evidenceData.evidenceList.map(
              v => ({
                ...v,
                evidenceDocument: response.data
              })
            )

            CaseService.insertEvidenceDocumentItemsBatch(
              this.evidenceData.evidenceList
            )

            if (!this.evidenceDocument.id) {
              this.evidenceDocumentList.push(this.evidenceDocument)
            }

            // this.evidenceDocumentList.push(this.evidenceData)
            this.evidenceDocument = {}
            this.evidenceData.evidenceList = []
            this.evidenceListDialog = false
          }
        },
        error => {
          this.resultMessage = error.response
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    createTask() {
      if (!this.$refs.formTaskList.validate()) return
      if (this.criminalCase == null) return

      this.taskDocument.criminalData = this.criminalCase

      CaseService.insertTrialTaskDocument(this.taskDocument).then(
        response => {
          if (response.data) {
            this.resultMessage = this.$t('message.addedData')
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            if (this.editedTaskIndex > -1) {
              Object.assign(
                this.caseTaskList[this.editedTaskIndex],
                this.taskDocument
              )
            } else {
              this.taskDocument.id = response.data.id
              this.caseTaskList.push(this.taskDocument)
            }

            this.taskDocument = {}
            this.editedTaskIndex = -1
            this.taskDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    async editEvidence(item) {
      this.editedEvidenceDocumentIndex = this.evidenceDocumentList.indexOf(item)
      this.evidenceDocument = Object.assign({}, item)
      var evidenceId = item.id

      await CaseService.getEvidenceItemByDocumentId(evidenceId).then(
        response => {
          this.evidenceData.evidenceList = response.data
        }
      )

      this.evidenceListDialog = true
    },
    onEvidenceDialogClose() {
      this.editedEvidenceDocumentIndex = -1
      this.evidenceDocument = {}
      this.evidenceData.evidenceList = []
      this.evidenceListDialog = false
    },
    addEvidenceItem() {
      this.evidenceData.evidenceList.push({})
    },
    removeEvidenceItem(index) {
      this.$confirm({
        message: 'Do you want to delete this item: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.evidenceData.evidenceList.splice(index, 1)
          }
        }
      })
    },
    async viewLawyer(item) {
      var documentId = item.id

      await CaseService.getLawyerDocumentAssignmentByDocumentId(
        documentId
      ).then(response => {
        this.documentLawyerList = response.data
      })
      this.documentLawyerDialog = true
    },
    genTest: function() {
      this.prosecutionData = {
        letterNo: '111198827កខ',
        appointmentDate: '2024-01-05',
        prosecutorName: 'Ly Rithy',
        clerkName: 'Chhuy Yarttak'
      }
    },
    getFieldText(item) {
      return `${item.nameKh}`
    },
    getCaseLetterTypeByTypeId(id) {
      return this.caseLetterTypeList.filter(function(elem) {
        if (elem.letterType.id === id) return elem
      })
    },

    async getAppointedJudges() {
      var caseId = this.$route.params.id
      await CaseService.getJudgeAppointmentByCaseIdAndPhase(caseId).then(
        response => {
          this.appointedJudgeList = response.data
        }
      )
    },
    addLawyerItem() {
      this.lawyerData.lawyers.push({})
    },
    removeLawyerItem(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.lawyerData.lawyers.splice(index, 1)
          }
        }
      })
    },
    addRelatedPerson(event) {
      this.relatedPersonList.push({
        caseId: this.criminalCase.id,
        partyRole: {}
      })
    },

    removeRelatedPerson(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.relatedPersonList.splice(index, 1)
          }
        }
      })
    },

    async viewEvidence(item) {
      await CaseService.getEvidenceItemByDocumentId(item.id).then(response => {
        if (response.data) {
          this.evidenceItems = response.data
          this.evidenceItemDialog = true
        }
      })
    },
    evienceItemDialog() {},

    checkEvidenceItem() {
      alert(JSON.stringify(this.evidenceData.evidenceList))
    },
    addChargePersonItem() {
      this.chargePersonList.push({ party: '', crimeType: [] })
    },
    openChargeDialog() {
      this.chargeDialog = true

      SystemService.getCaseCrimeTypeAll().then(response => {
        this.caseCrimeTypeList = response.data
      })
    },
    onChargeDialogClose() {
      this.chargeDialog = false
    },
    removeChargePersonItem(index) {
      this.$confirm({
        message: 'Do you want to delete this person: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.chargePersonList.splice(index, 1)
          }
        }
      })
    },
    createDecision() {
      if (!this.$refs.formDecisionDocumentList.validate()) return
      if (this.criminalCase == null) return

      this.decisionDocument.criminalData = this.criminalCase

      CaseService.insertTrialDecisionDocument(this.decisionDocument).then(
        response => {
          if (response.data) {
            this.resultMessage = 'Decision has been submitted'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            var judgementList = []

            this.chargePersonList.forEach((item, index) => {
              item.crimeType.forEach(t => {
                judgementList.push({
                  decisions: response.data,
                  party: item.party,
                  crimeType: t,
                  detentionChecked: item.detentionChecked,
                  detentionDate: item.detentionDate,
                  detentionPeriod: item.detentionPeriod,
                  detentionDelay1: item.detentionDelay1,
                  detentionDelay2: item.detentionDelay2
                })
              })
            })

            if (judgementList.length > 0) {
              CaseService.insertTrialDecisionJudgement(judgementList).then(
                response => {
                  this.resultMessage = 'Judegements have been added'
                  this.$notify({
                    group: 'message',
                    title: 'Notification',
                    type: 'success',
                    text: this.resultMessage
                  })
                }
              )
            }

            if (!this.decisionDocument.id) {
              this.decisionDocumentList.push(this.decisionDocument)
            }

            this.decisionDocumentDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    editDecisionDocument(item) {
      CaseService.getJudgementByDecisionId(item.id).then(response => {
        var judgementList = response.data
        this.judgement.party = judgementList[0].party
        this.judgement.crimeType = []

        judgementList.forEach(element => {
          this.judgement.crimeType.push(element.crimeType)
        })

        this.judgement.detentionStatus = judgementList[0].detentionStatus
        this.judgement.releaseDate = judgementList[0].releaseDate
        this.judgement.detentionDate = judgementList[0].detentionDate
        this.judgement.detentionDay = judgementList[0].detentionDay
        this.judgement.detentionMonth = judgementList[0].detentionMonth
        this.selectedPartyStatus = this.judgement.detentionStatus
      })

      this.editedDecisionIndex = this.decisionDocumentList.indexOf(item)
      this.decisionDocument = Object.assign({}, item)
      this.decisionDocumentDialog = true
    },
    editTaskDocument(item) {
      this.editedTaskIndex = this.caseTaskList.indexOf(item)
      this.taskDocument = Object.assign({}, item)
      this.taskDialog = true
    },
    onDecisionDocumentDialogClose() {
      this.editedDecisionIndex = -1
      this.decisionDocument = {}
      this.judgement = { party: '', crimeType: [] }
      this.selectedPartyStatus = ''
      this.decisionDocumentDialog = false
    },
    onTaskDialogClose() {
      this.editedTaskIndex = -1
      this.taskDocument = {}
      this.taskDialog = false
    },
    onViewJudgementDialogClose() {
      this.viewJudgementDialog = false
      this.chargePersonList = [{ party: '', crimeType: [] }]
    },
    async showJudgement(item) {
      await CaseService.getJudgementByDecisionId(item.id).then(response => {
        var judgementList = response.data
        var crimeTypeList = []
        judgementList.forEach(element => {
          crimeTypeList.push(element.crimeType)
        })

        this.displayJudgement.party = judgementList[0].party
        this.displayJudgement.detentionStatus = judgementList[0].detentionStatus
        this.displayJudgement.detentionDate = judgementList[0].detentionDate
        this.displayJudgement.detentionDay = judgementList[0].detentionDay
        this.displayJudgement.detentionMonth = judgementList[0].detentionMonth
        this.displayJudgement.crimeTypes = crimeTypeList
        this.viewJudgementDialog = true
      })
    },
    async selectPartyForJudgement(item) {
      this.resetJudgementData()
      var id = this.judgement.party.id
      await CaseService.getPartyJudgementInPhase2(id).then(response => {
        this.judgement.crimeType = []
        //    alert(JSON.stringify(response.data))
        response.data.forEach(element => {
          if (element.detentionStatus) {
            this.judgement.detentionStatus = element.detentionStatus
            switch (element.detentionStatus) {
              case 1:
                this.judgement.detentionChecked = element.detentionChecked
                this.judgement.detentionDate = element.detentionDate
                this.judgement.detentionPeriod = element.detentionPeriod
                this.judgement.detentionDelay1 = element.detentionDelay1
                this.judgement.detentionDelay2 = element.detentionDelay2
                break
              case 2:
                this.resetJudgementData()
                this.judgement.releaseDate = element.releaseDate
                break
              case 3:
                this.resetJudgementData()
                break
              case 4:
                this.resetJudgementData()
                break
            }
          }
          if (
            this.judgement.crimeType.find(
              item => item.id === element.crimeType.id
            )
          ) {
          } else {
            this.judgement.crimeType.push(element.crimeType)
          }
        })
      })
    },
    resetJudgementData() {
      this.judgement.detentionChecked = false
      this.judgement.detentionDate = ''
      this.judgement.detentionPeriod = ''
      this.judgement.detentionDelay1 = ''
      this.judgement.detentionDelay2 = ''
      this.judgement.releaseDate = ''
    },
    async selectPartyStatus(item) {
      this.judgement.detentionStatus = item
      this.selectedPartyStatus = item
    },

    async detailWarrent(item) {
      alert(JSON.stringify(item))
    }
  }
}
</script>
<style scoped>
.right-side-dialog {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 600px;
  width: 100%;
  overflow-y: auto;
}

.right-side-dialog-800 {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 800px;
  width: 100%;
  overflow-y: auto;
}

.right-side-dialog-750 {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 750px;
  width: 100%;
  overflow-y: auto;
}

.slide-x-transition-enter-active,
.slide-x-transition-leave-active {
  transition: transform 0.3s ease;
}

.slide-x-transition-enter {
  transform: translateX(100%);
}

.slide-x-transition-leave-to {
  transform: translateX(100%);
}
</style>