import api from './index'
import authHeader from './auth-header'

class ServiceCase {
    getCaseAll() {
        return api.get('criminal/case/all', { headers: authHeader() })
    }

    getPartyByCaseId(caseId) {
        return api.get('criminal/party/caseId/' + caseId, { headers: authHeader() })
    }

    getDefendedPartyByCaseId(caseId) {
        return api.get('criminal/party/caseId/' + caseId + '/hasLawyer', { headers: authHeader() })
    }

    getDetainedPartyByCaseId(caseId) {
        return api.get('criminal/party/caseId/' + caseId + '/detained', { headers: authHeader() })
    }

    getPartyJudgementInPhase2(partyId) {
        return api.get('criminal/party/' + partyId + '/phase2/judgement', { headers: authHeader() })
    }


    insert(formData) {
        return api.post('criminal/case/create', formData, { headers: authHeader() })
    }

    insertPartyBatch(formData) {
        return api.post('criminal/party/createBatch', formData, { headers: authHeader() })
    }

    insertChargeBatch(formData) {
        return api.post('criminal/charge/createBatch', formData, { headers: authHeader() })
    }

    getChargeByCaseId(id) {
        return api.get('criminal/charge/caseId/' + id, { headers: authHeader() })
    }

    getCaseById(id) {
        return api.get('criminal/case/' + id, { headers: authHeader() })
    }

    getCasePartyRoleAll() {
        return api.get('criminal/party/role/all', { headers: authHeader() })
    }

    insertAppointment(formData) {
        return api.post('criminal/appointment/create', formData, { headers: authHeader() })
    }

    insertJudgeAppointment(formData) {
        return api.post('criminal/judgeAppointment/create', formData, { headers: authHeader() })
    }

    insertTrialAppointment(formData) {
        return api.post('criminal/trialAppointment/create', formData, { headers: authHeader() })
    }

    getAppointmentByCaseId(caseId) {
        return api.get('criminal/appointment/caseId/' + caseId, { headers: authHeader() })
    }

    getJudgeAppointmentByCaseId(caseId) {
        return api.get('criminal/judgeAppointment/caseId/' + caseId, { headers: authHeader() })
    }

    getJudgeAppointmentByCaseIdAndPhase(caseId) {
        return api.get('criminal/appointedJudges/caseId/' + caseId, { headers: authHeader() })
    }

    getTrialAppointmentByCaseIdAndPhase(caseId) {
        return api.get('criminal/appointedTrials/caseId/' + caseId, { headers: authHeader() })
    }

    getTrialAppointmentByCaseId(caseId) {
        return api.get('criminal/trialAppointment/caseId/' + caseId, { headers: authHeader() })
    }

    insertWarrant(formData) {
        return api.post('criminal/warrant/create', formData, { headers: authHeader() })
    }

    insertJudgeWarrant(formData) {
        return api.post('criminal/judgeWarrant/create', formData, { headers: authHeader() })
    }

    insertTrialWarrant(formData) {
        return api.post('criminal/trialWarrant/create', formData, { headers: authHeader() })
    }

    getWarrantByCaseId(caseId) {
        return api.get('criminal/warrant/caseId/' + caseId, { headers: authHeader() })
    }

    getAllWarrantByCaseId(caseId) {
        return api.get('criminal/warrants/caseId/' + caseId, { headers: authHeader() })
    }

    getAllWarrantHistoryByCaseId(caseId) {
        return api.get('criminal/warrantHistory/caseId/' + caseId, { headers: authHeader() })
    }

    getJudgeWarrantByCaseId(caseId) {
        return api.get('criminal/judgeWarrant/caseId/' + caseId, { headers: authHeader() })
    }

    getTrialWarrantByCaseId(caseId) {
        return api.get('criminal/trialWarrant/caseId/' + caseId, { headers: authHeader() })
    }

    insertLawyerDocument(formData) {
        return api.post('criminal/lawyerDocument/create', formData, { headers: authHeader() })
    }

    insertJudgeLawyerDocument(formData) {
        return api.post('criminal/judgeLawyerDocument/create', formData, { headers: authHeader() })
    }

    insertTrialLawyerDocument(formData) {
        return api.post('criminal/trialLawyerDocument/create', formData, { headers: authHeader() })
    }

    getLawyerDocumentByCaseId(caseId) {
        return api.get('criminal/lawyerDocument/caseId/' + caseId, { headers: authHeader() })
    }

    getJudgeLawyerDocumentByCaseId(caseId) {
        return api.get('criminal/judgeLawyerDocument/caseId/' + caseId, { headers: authHeader() })
    }

    getTrialLawyerDocumentByCaseId(caseId) {
        return api.get('criminal/trialLawyerDocument/caseId/' + caseId, { headers: authHeader() })
    }

    getLawyerDocumentHistoryByCaseId(caseId) {
        return api.get('criminal/lawyerDocumentHistory/caseId/' + caseId, { headers: authHeader() })
    }

    insertLawyerDocumentAssignmentBatch(formData) {
        return api.post('criminal/lawyerDocument/assignment/createBatch', formData, { headers: authHeader() })
    }

    getLawyerDocumentAssignmentByDocumentId(documentId) {
        return api.get('criminal/lawyerDocument/assignment/documentId/' + documentId, { headers: authHeader() })
    }

    insertEvidenceDocument(formData) {
        return api.post('criminal/evidenceDocument/create', formData, { headers: authHeader() })
    }

    getEvidenceDocumentByCaseId(caseId) {
        return api.get('criminal/evidenceDocument/caseId/' + caseId, { headers: authHeader() })
    }

    insertEvidenceDocumentItemsBatch(formData) {
        return api.post('criminal/evidenceDocument/item/createBatch', formData, { headers: authHeader() })
    }

    getEvidenceItemByDocumentId(documentId) {
        return api.get('criminal/evidenceDocument/documentId/' + documentId, { headers: authHeader() })
    }

    insertDecisionDocument(formData) {
        return api.post('criminal/decisionDocument/create', formData, { headers: authHeader() })
    }

    insertJudgeDecisionDocument(formData) {
        return api.post('criminal/judgeDecisionDocument/create', formData, { headers: authHeader() })
    }

    insertTrialDecisionDocument(formData) {
        return api.post('criminal/trialDecisionDocument/create', formData, { headers: authHeader() })
    }

    getDecisionDocumentByCaseId(caseId) {
        return api.get('criminal/decisionDocument/caseId/' + caseId, { headers: authHeader() })
    }

    getDecisionDocumentHistoryByCaseId(caseId) {
        return api.get('criminal/decisionDocumentHistory/caseId/' + caseId, { headers: authHeader() })
    }
    getJudgeDecisionDocumentByCaseId(caseId) {
        return api.get('criminal/judgeDecisionDocument/caseId/' + caseId, { headers: authHeader() })
    }

    getTrialDecisionDocumentByCaseId(caseId) {
        return api.get('criminal/trialDecisionDocument/caseId/' + caseId, { headers: authHeader() })
    }

    insertDecisionJudgement(formData) {
        return api.post('criminal/pros/judgement/createBatch', formData, { headers: authHeader() })
    }

    insertJudgeDecisionJudgement(formData) {
        return api.post('criminal/judge/judgement/createBatch', formData, { headers: authHeader() })
    }

    insertTrialDecisionJudgement(formData) {
        return api.post('criminal/trial/judgement/createBatch', formData, { headers: authHeader() })
    }

    insertTaskDocument(formData) {
        return api.post('criminal/taskDocument/create', formData, { headers: authHeader() })
    }

    insertJudgeTaskDocument(formData) {
        return api.post('criminal/judgeTaskDocument/create', formData, { headers: authHeader() })
    }

    insertTrialTaskDocument(formData) {
        return api.post('criminal/trialTaskDocument/create', formData, { headers: authHeader() })
    }

    getTaskDocumentByCaseId(caseId) {
        return api.get('criminal/taskDocument/caseId/' + caseId, { headers: authHeader() })
    }

    getTaskDocumentHistoryByCaseId(caseId) {
        return api.get('criminal/taskDocumentHistory/caseId/' + caseId, { headers: authHeader() })
    }

    getJudgeTaskDocumentByCaseId(caseId) {
        return api.get('criminal/judgeTaskDocument/caseId/' + caseId, { headers: authHeader() })
    }

    getTrialTaskDocumentByCaseId(caseId) {
        return api.get('criminal/trialTaskDocument/caseId/' + caseId, { headers: authHeader() })
    }

    getJudgementByDecisionId(decisionId) {
        return api.get('criminal/judgement/decisionId/' + decisionId, { headers: authHeader() })
    }
}

export default new ServiceCase()