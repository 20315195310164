<template>
  <div>
    <v-card flat>
      <v-toolbar flat color="primary">
        <v-toolbar-title class="flex text-center">
          <span class="primary--text-kh" style="color:white"> {{$t('case.initialInfo')}}</span>
        </v-toolbar-title>
      </v-toolbar>
      <br />
      <v-card-text>
        <center>
          <v-row class="d-flex justify-center" dense>
            <v-col cols="12" class="ml-10 d-flex justify-center">
              <span class="mx-4 primary--text">{{$t('case.caseNo')}}: {{criminalCase.caseNo}} ចុះថ្ងៃទី {{criminalCase.caseRegisDate}}</span>
            </v-col>
            <v-col cols="12" class="ml-10 d-flex justify-center">
              <span class="mx-4 primary--text">កាលបរិច្ឆេទចុះក្នុងប្រព័ន្ធ: {{criminalCase.caseOrigDate}}</span>
            </v-col>
            <v-col cols="12" class="ml-10 d-flex justify-center">
              <span class="mx-4 primary--text" v-if="criminalCase.court">{{$t('case.courtName')}}: {{criminalCase.court.nameKh}}</span>
            </v-col>
          </v-row>
        </center>
      </v-card-text>
      <v-row>
        <v-col cols="6">
          <v-card-title class="justify-center">
            <span class="primary--text">{{$t('case.plaintiffName')}}</span>
          </v-card-title>
          <v-card-text>
            <v-row class="d-flex justify-center" dense v-for="(item, index) in partyList" :key="item.id" v-if="item.partyRole.roleNameEn=='Plaintiff'">
              <v-col cols="12" sm="5" class="ml-10 d-flex justify-left">
                <label class="mx-4 info--text">
                  {{item.nameKh}}
                </label>
              </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-left">
                <label class="mx-4 success--text">
                  ភេទ
                  <span v-if="item.gender">{{$t('text.female')}}</span>
                  <span v-else>{{$t('text.male')}}</span>
                </label>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="6">
          <v-card-title class="justify-center">
            <span class="primary--text">{{$t('case.defendantName')}}</span>
          </v-card-title>
          <v-card-text>
            <center>
              <v-row class="d-flex justify-center" dense v-for="(item, index) in partyList" :key="item.id" v-if="item.partyRole.roleNameEn=='Suspect'">
                <v-col cols="12" sm="5" class="ml-10 d-flex justify-left">
                  <label class="mx-4 info--text">
                    {{item.nameKh}}
                  </label>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-left">
                  <label class="mx-4 success--text">
                    ភេទ
                    <span v-if="item.gender">{{$t('text.female')}}</span>
                    <span v-else>{{$t('text.male')}}</span>
                  </label>
                </v-col>
              </v-row>
            </center>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="my-5">
      <v-toolbar flat>
        <v-toolbar-title class="flex text-center">
          <span class="primary--text-kh">
            <u> {{$t('case.casePhase')}} </u>
          </span>
        </v-toolbar-title>
      </v-toolbar>
      <br />
      <v-card-text>
        <v-tabs v-model="tab" color="primary" class="flat-tabs" background-color="transparent" slider-color="primary" fixed-tabs>
          <v-tab value="option-2">
            ដំណាក់កាលអយ្យការ
          </v-tab>
          <v-tab value="option-3">
            ដំណាក់កាលចៅក្រមស៊ើបសួរ
          </v-tab>
          <v-tab value="option-4">
            ដំណាក់កាលជំនុំជម្រះ
          </v-tab>
          <v-tab value="option-5">
            បន្តបណ្តឹងទៅឧទ្ធរណ៍
          </v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item key="option-2">
            <v-card flat>
              <v-card-text>
                <prosecution-phase-section></prosecution-phase-section>
              </v-card-text>
            </v-card>
          </v-window-item>
          <v-window-item key="option-3">
            <v-card flat>
              <v-card-text>
                <judge-phase-section></judge-phase-section>
              </v-card-text>
            </v-card>
          </v-window-item>
          <v-window-item key="option-4">
            <v-card flat>
              <v-card-text>
                <trial-phase-section></trial-phase-section>
              </v-card-text>
            </v-card>
          </v-window-item>
          <v-window-item key="option-5">
            <v-card flat>
              <v-card-text>
                Under construction
              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCalendarAlert, mdiPencil } from '@mdi/js'
import ProsecutionPhaseSection from './criminal/ProsecutionPhaseSection.vue'
import JudgePhaseSection from './criminal/JudgePhaseSection.vue'
import TrialPhaseSection from './criminal/TrialPhaseSection.vue'
import CaseService from '@/services/service.case'

export default {
  name: 'oath-cases',
  components: {
    ProsecutionPhaseSection,
    JudgePhaseSection,
    TrialPhaseSection
  },
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPencil
      },
      tab: null,
      criminalCase: {},
      partyList: [],
      prosData: { partyList: [] }
    }
  },
  async created() {
    if (this.$route.params.id) {
      var caseId = this.$route.params.id

      await CaseService.getCaseById(caseId).then(response => {
        this.criminalCase = response.data
      })

      await CaseService.getPartyByCaseId(this.criminalCase.id).then(
        response => {
          this.partyList = response.data
        }
      )
    }
  },
  methods: {
    updateProsData(updatedData) {
      this.prosData.partyList = updatedData
      this.partyList = this.prosData.partyList

      // alert(JSON.stringify(this.prosData.partyList));
    }
  }
}
</script>
<style scoped>
.flat-tabs .v-tabs-bar {
  box-shadow: none;
  border-bottom: none;
}

.flat-tabs .v-tab {
  border-bottom: none;
  padding: 0;
}

.flat-tabs .v-tabs-bar__content {
  border-bottom: none;
}
</style>

